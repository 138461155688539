<template>
  <hg-footer-new
    @onCookieButtonClick="cookieButtonClick"
    :activate-cookie-consent="!noCookieConsent"
    :lang="$i18n.locale"
    class="footer-margin"
  />
</template>

<script>
export default {
  props: {
    noCookieConsent: {
      type: Boolean,
      default: false
    },
    noCookieLoader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cookieButtonClick() {
      window._sp_.loadPrivacyManagerModal(
        this.$i18n.t('privacyManagerModalId')
      );
    }
  }
};
</script>

<style lang="scss">
.footer-margin {
  margin-top: rem(45);
  padding-bottom: rem(88);
}
</style>
