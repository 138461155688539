<template>
  <div class="holidayguru">
    <client-only>
      <HgFooter
        v-if="!['de', 'at', 'ch', 'nl', 'es'].includes($i18n.locale)"
        :lang="$i18n.locale"
        :activate-cookie-consent="!noCookieConsent"
        :has-bottom-safe-space="hasBottomSafeSpace()"
        @onCookieButtonClick="cookieButtonClick"
        @onNewsletterButtonClick="newsletterButtonClick"
      >
      </HgFooter>

      <FooterSection v-else />
    </client-only>
  </div>
</template>

<script>
import FooterSection from '@/components/sections/FooterSection';
export default {
  components: { FooterSection },
  props: {
    noCookieConsent: {
      type: Boolean,
      default: false
    },
    noCookieLoader: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route() {
      this.hasBottomSafeSpace();
    }
  },
  methods: {
    hasBottomSafeSpace() {
      return this.$nuxt.$route.name && this.$nuxt.$route.name.startsWith('deal-');
    },
    newsletterButtonClick() {
      window.location = '/newsletter/';
    },
    cookieButtonClick() {
      window._sp_.loadPrivacyManagerModal(
        this.$i18n.t('privacyManagerModalId')
      );
    }
  }
};
</script>

<style lang="postcss">
.hg-button {
  white-space: nowrap;
}
</style>
